import type { ParagraphLibraryType, ParagraphType } from '@packages/cms-components/interfaces';
import { ComponentTypes } from '@packages/cms-components/interfaces';

const containsNewsletterParagraph = (paragraph: ParagraphType): boolean =>
  paragraph.type === 'newsletter';

const containsPlaceholderParagraphWithContactForm = (paragraph: ParagraphType): boolean =>
  paragraph.type === ComponentTypes.Placeholder &&
  paragraph.field_placeholder_variants === 'contactForm';

const containsLibraryItemWithNewsletterOrContact = (
  paragraph: ParagraphType | ParagraphLibraryType,
): boolean =>
  paragraph.type === ComponentTypes.FromLibrary &&
  paragraph.paragraphs_library_item?.some((lib: { paragraph: ParagraphType[] }) =>
    lib.paragraph.some(
      (lp) => containsNewsletterParagraph(lp) || containsPlaceholderParagraphWithContactForm(lp),
    ),
  );

const containsForbiddenParagraph: (paragraph: ParagraphType | ParagraphLibraryType) => boolean = (
  paragraph,
) =>
  containsNewsletterParagraph(paragraph) ||
  containsPlaceholderParagraphWithContactForm(paragraph) ||
  containsLibraryItemWithNewsletterOrContact(paragraph) ||
  ((paragraph.type === ComponentTypes.Group ||
    paragraph.type === ComponentTypes.GroupLocalizable ||
    paragraph.type === ComponentTypes.GroupApp) &&
    paragraph.paragraph.some((p: ParagraphType | ParagraphLibraryType) =>
      containsForbiddenParagraph(p),
    ));

/**
 * Helper function to tell you if the NewsletterSheet should be diyplayes on the current page,
 * based on what Paragraphs are on it.
 */
export const showNewsletterSheet = (paragraphs: (ParagraphType | ParagraphLibraryType)[]) =>
  paragraphs && !paragraphs.some((p) => containsForbiddenParagraph(p));
