import { CmsComponents } from '../ComponentRecognizer';
import type { CmsData, MenuType } from '../../../interfaces';
import { registerFlexibleComponents } from '../ComponentRecognizer/registerFlexibleComponents';
import { ContentWithMenu } from './ContentWithMenu';

// register the dynamic components
registerFlexibleComponents();

export const Content = ({
  data,
  menuData,
  token,
}: {
  data: CmsData;
  menuData?: MenuType;
  token?: string;
}) => {
  const pageTemplate = data.type === 'storefront_content' ? 'homepage' : 'ServiceUndHilfe';
  if (menuData) {
    return (
      <ContentWithMenu menuData={menuData} currentPath={data.path?.[0].alias}>
        <CmsComponents paragraphs={data.paragraph} pageTemplate={pageTemplate} token={token} />
      </ContentWithMenu>
    );
  }

  return <CmsComponents paragraphs={data.paragraph} pageTemplate={pageTemplate} token={token} />;
};
