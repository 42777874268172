type ShouldDisplayChatBotConfig = {
  path: string | string[];
  includePaths: string[];
  excludePaths: string[];
};

/**
 * Determines whether the ChatBot should be displayed based on the given path and configuration.
 * @param {string | string[]} path The current path or array of path segments to evaluate.
 * @param {string[]} includePaths Paths where the ChatBot should be included.
 * @param {string[]} excludePaths Paths where the ChatBot should be excluded.
 * @returns {boolean} True if the ChatBot should be displayed, false otherwise.
 */
export const shouldDisplayChatBot = ({
  path,
  includePaths,
  excludePaths,
}: ShouldDisplayChatBotConfig): boolean => {
  const pathname = Array.isArray(path) ? path.join('/') : path;
  const shouldInclude = includePaths.some((includePath) => pathname.includes(includePath));
  const shouldExclude = excludePaths.some((excludePath) => pathname.includes(excludePath));
  return shouldInclude && !shouldExclude;
};
