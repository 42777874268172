import useSWR from 'swr/immutable';
import { bucketFetcher, getSurveyEndpoint } from '../utils';
import { useCmsFetcherDetails } from './useCmsFetcherDetails';
import type { SurveyData } from '../modules/Survey/types';

/**
 * Fetches and transforms Survey data using the SWR (Stale-While-Revalidate) strategy.
 *
 * @returns The Survey data, or undefined if the data is not yet available.
 */
export const useSurveyData = () => {
  const { language, cacheKey } = useCmsFetcherDetails('active-survey');
  const endpoint = getSurveyEndpoint(language);
  const { data } = useSWR<SurveyData>(cacheKey, () => bucketFetcher<SurveyData>(endpoint, 240)());

  return data;
};
