'use client';

import { useSearchParams } from 'next/navigation';
import { filterTimeControl } from '@packages/shared/src/utils/filterTimeControl/filterTimeControl';
import { SurveyModal } from './Survey.modal';
import type { SurveyData } from './types';

/**
 * SurveyWrapper component.
 *
 * @param data - The Survey data.
 *
 * @returns JSX.Element|null The rendered SurveyWrapper component or null if no valid children exist.
 */
export const SurveyWrapper = ({ data }: SurveyData) => {
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { TestDate, previewCurrentSurvey = false, forceDisableSurvey = false } = query;
  const isPreview = Boolean(previewCurrentSurvey) && previewCurrentSurvey !== '';

  if (!forceDisableSurvey && data && (filterTimeControl(data, TestDate) || isPreview)) {
    return <SurveyModal data={data} isPreview={isPreview} />;
  }

  return null;
};
